import React, { Component, Fragment } from "react"

import UECPublicHeader from "../components/UECPublicHeader"
import UECPublicFooter from "../components/UECPublicFooter"
import StyleBase from "../styles/base"

import { OpenDoorIcon } from "../icons"
import { CDN_DOMAIN } from "../config/cdn"
import typesURLMatching from "../datas/typesURLMatching"

class ErrorPage extends Component {
    render() {
        const {
            type,
            config,
            error,
            errorCode,
            disablePublicHeader
        } = this.props

        return (
            <Fragment>
                {!disablePublicHeader && (
                    <UECPublicHeader type={type} config={config} />
                )}
                <section
                    style={{
                        backgroundColor: "#f7f7f7",
                        paddingTop: 78,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        minHeight: 500
                    }}
                >
                    <div style={{ margin: "auto", textAlign: "center" }}>
                        {disablePublicHeader && (
                            <img
                                src={`${CDN_DOMAIN}/static/logo_green_mobile.png`}
                                alt="Unemplacement.com"
                                title="Unemplacement.com"
                                className="logo-uec"
                                style={{
                                    width: 220
                                }}
                            />
                        )}
                        <div
                            style={{
                                margin: "auto",
                                position: "relative",
                                width: 300,
                                height: 300
                            }}
                        >
                            <OpenDoorIcon
                                width={300}
                                height={300}
                                style={{
                                    position: "absolute",
                                    left: 35,
                                    top: 0
                                }}
                            />
                            <div
                                style={{
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    fontWeight: "bold",
                                    fontSize: 28,
                                    color: "#0db3ac",
                                    position: "relative",
                                    top: 100,
                                    textAlign: "center",
                                    textShadow: "0.5px 0.5px 0px #0dc4bb"
                                }}
                            >
                                <div>Erreur</div>
                                <div style={{ marginTop: 30 }}>{errorCode}</div>
                            </div>
                        </div>
                    </div>
                    {errorCode === 404 && (
                        <div style={{ margin: "auto", textAlign: "center" }}>
                            <div
                                style={{
                                    textAlign: "center",
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    color: "#4A4A4A",
                                    maxWidth: 800,
                                    margin: "auto"
                                }}
                            >
                                <h1>
                                    Désolé, l’adresse que vous demandez est
                                    introuvable
                                </h1>
                                <p style={{ fontWeight: 300 }}>
                                    La page que vous avez demandée n’a pas été
                                    trouvée. Il se peut que le lien que vous
                                    avez utilisé soit rompu ou que vous ayez
                                    tapé l’adresse (URL) incorrectement.
                                </p>
                                <div
                                    style={{
                                        marginTop: 30,
                                        marginBottom: 100
                                    }}
                                >
                                    <div
                                        className="row"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div
                                            className="col-12 col-md-4"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a href="/" className="green-link">
                                                Confier ma recherche
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-4"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['bureau']}`}
                                                className="green-link"
                                            >
                                                Bureaux
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-4"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['local-commercial']}`}
                                                className="green-link"
                                            >
                                                Locaux commerciaux
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="row"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div
                                            className="col-12 col-md-4"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['local-commercial']}`}
                                                className="green-link"
                                            >
                                                Fonds de commerce
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-4"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['popup-store']}`}
                                                className="green-link"
                                            >
                                                Pop-up store
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-4"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['coworking']}`}
                                                className="green-link"
                                            >
                                                Coworking
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className="row"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div
                                            className="col-12 col-md-6"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['entrepot']}`}
                                                className="green-link"
                                            >
                                                Entrepôts / Activités
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-6"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['terrain']}`}
                                                className="green-link"
                                            >
                                                Terrains
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {errorCode === 500 && (
                        <div style={{ margin: "auto", textAlign: "center" }}>
                            <div
                                style={{
                                    textAlign: "center",
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    color: "#4A4A4A",
                                    maxWidth: 800,
                                    margin: "auto"
                                }}
                            >
                                <h1>
                                    Désolé, nous avons des difficultés à
                                    afficher cette page
                                </h1>
                                <p style={{ fontWeight: 300 }}>
                                    Nous rencontrons actuellement des
                                    difficultés à afficher cette page. Nous
                                    travaillons actuellement à régler le
                                    problème. Si le problème persiste, veuillez
                                    contacter l'administrateur à l'adresse
                                    suivante :{" "}
                                    <a
                                        href="mailto:contact@unemplacement.com?subject=Erreur 500"
                                        className="green-link"
                                    >
                                        contact@unemplacement.com
                                    </a>
                                </p>
                                <div
                                    style={{
                                        marginTop: 30,
                                        marginBottom: 100
                                    }}
                                >
                                    <div
                                        className="row"
                                        style={{ alignItems: "center" }}
                                    >
                                        <div
                                            className="col-12 col-md-3"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a href="/" className="green-link">
                                                Accueil
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-3"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a href="/" className="green-link">
                                                Confier ma recherche
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-3"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['local-commercial']}`}
                                                className="green-link"
                                            >
                                                Local commercial
                                            </a>
                                        </div>
                                        <div
                                            className="col-12 col-md-3"
                                            style={{ paddingBottom: 30 }}
                                        >
                                            <a
                                                href={`/${typesURLMatching['terrain']}`}
                                                className="green-link"
                                            >
                                                Terrains
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
                <UECPublicFooter config={config} />
                <StyleBase />
            </Fragment>
        )
    }
}

export default ErrorPage
