import React, { Component, Fragment } from "react"
import { slide as Menu } from "react-burger-menu"
import Link from "next/link"
import { withRouter } from "next/router"
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { MapSearchIcon } from "../icons"
import { CDN_DOMAIN } from "../config/cdn"
import typesURLMatching from "../datas/typesURLMatching"
import { generateObfuscatedLink } from "../helpers/SEO"

class UECPublicHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openedDropdown: {}
        }

        this.menus = {
            "/annonces": {
                menu: [
                    {
                        label: "Bureaux",
                        link: `/${typesURLMatching['bureau']}`
                    },
                    {
                        label: "Coworking",
                        link: `/${typesURLMatching['coworking']}`
                    },
                    {
                        label: "Commerce",
                        link: "#",
                        nolink: true,
                        dropdown: [
                            {
                                label: "Local commercial",
                                link: `/${typesURLMatching['local-commercial']}`
                            },
                            {
                                label: "Fonds de commerce",
                                link: `/${typesURLMatching['fonds-commerce']}`
                            },
                            {
                                label: "Pop-up store",
                                link: `/${typesURLMatching['popup-store']}`
                            }
                        ]
                    },
                    {
                        label: "Entrepôts / Activités",
                        link: `/${typesURLMatching['entrepot']}`
                    },
                    {
                        label: "Terrains",
                        link: `/${typesURLMatching['terrain']}`
                    },
                    {
                        label: "Choisir une agence",
                        link: `/agence-immobiliere`
                    }
                ],
                button: {
                    label: "Confier ma recherche",
                    link: "/",
                    width: 200
                }
            }
        }
    }

    render() {
        const menu = this.menus[this.props.router.asPath]
            ? this.menus[this.props.router.asPath].menu
            : this.menus["/annonces"].menu
        const button = this.menus[this.props.router.asPath]
            ? this.menus[this.props.router.asPath].button
            : this.menus["/annonces"].button

        return (
            <Fragment>
                <div
                    style={{
                        position: "fixed",
                        zIndex: 1000,
                        top: 0,
                        width: "100%"
                    }}
                >
                    <Menu
                        styles={{
                            bmCrossButton: {
                                height: "35px",
                                width: "40px"
                            }
                        }}
                        burgerButtonClassName="public-header-burger-button"
                    >
                        {menu.map((entry, i) => (
                            <div key={i}>
                                {!entry.dropdown &&
                                    <Link href={entry.link} prefetch={false}>
                                        <a
                                            className={`menu-item${
                                                entry.link === `/${typesURLMatching[this.props.type]}`
                                                    ? " hover"
                                                    : ""
                                            }`}
                                        >
                                            {entry.label}
                                        </a>
                                    </Link>
                                }
                                {entry.dropdown && (
                                    <a
                                        className={`menu-item${
                                            this.state.openedDropdown[i]
                                                ? " hover"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            if (this.state.openedDropdown[i]) {
                                                this.setState({
                                                    openedDropdown: {
                                                        ...this.state
                                                            .openedDropdown,
                                                        [i]: false
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    openedDropdown: {
                                                        ...this.state
                                                            .openedDropdown,
                                                        [i]: true
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        {entry.label}
                                    </a>
                                )}
                                {entry.dropdown &&
                                    this.state.openedDropdown[i] && (
                                        <div
                                            style={{
                                                marginLeft: 10,
                                                marginTop: 30,
                                                border: "1px solid #0db3ac",
                                                padding: 5,
                                                paddingLeft: 10,
                                                borderRadius: 5
                                            }}
                                        >
                                            {entry.dropdown.map((o, j) => {
                                                return (
                                                    <div
                                                        key={`${i}_${j}`}
                                                        style={{
                                                            position: "relative",
                                                            marginTop: 20,
                                                            marginBottom: 20
                                                        }}
                                                    >
                                                        <Link href={o.link}>
                                                            <a
                                                                className={`menu-item${
                                                                    o.link ===
                                                                    this.props
                                                                        .router
                                                                        .asPath
                                                                        ? " hover"
                                                                        : ""
                                                                }`}
                                                                target={
                                                                    o.blank
                                                                        ? "_blank"
                                                                        : undefined
                                                                }
                                                            >
                                                                {o.label}
                                                            </a>
                                                        </Link>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                            </div>
                        ))}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            {generateObfuscatedLink(
                                "/professionnels-immobilier",
                                <div>
                                    Vous êtes pro ?{' '}
                                    <OpenInNewIcon
                                        style={{
                                            color:
                                                '#0db3ac',
                                            width: 17,
                                            height: 17,
                                            marginLeft: 2
                                        }}
                                    />
                                </div>,
                                "green-olink",
                                {
                                    fontSize: 16,
                                    marginTop: 0,
                                    marginBottom: 40
                                }
                            )}
                            {generateObfuscatedLink(
                                button.link,
                                button.label,
                                "uec-button cta-prospection-listing",
                                {
                                    width: button.width || 180
                                }
                            )}
                        </div>
                    </Menu>
                </div>
                <div
                    style={{
                        display: "flex",
                        position: "fixed",
                        zIndex: 120,
                        top: 0,
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        marginTop: 0,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 8,
                        paddingTop: 6,
                        borderBottom: "1px solid #e2e2e2",
                        background: "white",
                        height: 58,
                        ...(this.props.style || {})
                    }}
                >
                    <div style={{ flexGrow: 1, maxWidth: 160 }}>
                        <a href="/" style={{ border: 0, margin: "auto" }}>
                            <img
                                src={`${CDN_DOMAIN}/static/logo_green_mobile.png`}
                                alt="Unemplacement.com"
                                title="Unemplacement.com"
                                className="logo-uec"
                                style={{
                                    width: 160
                                }}
                            />
                        </a>
                    </div>
                    {(this.props.brandSubscribeHeaderButton ||
                        this.props.router.pathname === "/annonce") && (
                        <div
                            className="uec-send-brand-public-button cta-prospection-ads"
                            style={{
                                color: "white",
                                fontSize: 13,
                                display: "flex",
                                alignItems: "center",
                                maxWidth: 102,
                                background: "#0db3ac",
                                borderRadius: 5,
                                padding: 3,
                                fontFamily:
                                    "Lato, Roboto, Helvetica, Arial, sans-serif",
                                marginLeft: 13,
                                marginTop: 5
                            }}
                            onClick={() => {
                                window.location = "/"
                            }}
                        >
                            <div>Confier ma recherche</div>
                            <MapSearchIcon
                                color="white"
                                height={30}
                                width={30}
                            />
                        </div>
                    )}
                    <div className="menu-public">
                        <ul>
                            {menu.map((entry, i) => (
                                <li
                                    key={i}
                                    className={
                                        entry.dropdown
                                            ? "dropdown-button"
                                            : undefined
                                    }
                                >
                                    {entry.dropdown &&
                                        <div
                                            style={{
                                                position: "relative",
                                                zIndex: 120,
                                                top: 18
                                            }}
                                        >
                                            <ul
                                                className="dropdown"
                                                style={{
                                                    paddingTop: 7,
                                                    paddingBottom: 7
                                                }}
                                            >
                                                {entry.dropdown.map((o, i) => (
                                                    <li key={i}>
                                                        <a
                                                            className={
                                                                o.customClass
                                                                    ? o.customClass
                                                                    : "dropdown-link"
                                                            }
                                                            style={{
                                                                paddingTop: 6,
                                                                paddingBottom: 6
                                                            }}
                                                            href={o.link}
                                                            target={
                                                                o.blank
                                                                    ? "_blank"
                                                                    : undefined
                                                            }
                                                        >
                                                            {o.label}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                    {!entry.nolink &&
                                        <a
                                            href={entry.link}
                                            className={`${
                                                entry.link ===
                                                `/${typesURLMatching[this.props.type]}`
                                                    ? "hover"
                                                    : ""
                                            }`}
                                        >
                                            {entry.label}
                                        </a>
                                    }
                                    {entry.nolink &&
                                        <div className="nolink">{entry.label}</div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                    {generateObfuscatedLink(
                        "/professionnels-immobilier",
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            Vous êtes pro ?{' '}
                            <OpenInNewIcon
                                style={{
                                    color:
                                        '#0db3ac',
                                    width: 16,
                                    height: 16,
                                    marginLeft: 2
                                }}
                            />
                        </div>,
                        "green-olink uec-pro-button cta-pro-listing",
                        {
                            position: "absolute",
                            right: 235,
                            fontSize: 14
                        }
                    )}
                    {generateObfuscatedLink(
                        button.link,
                        button.label,
                        "uec-button uec-public-button cta-prospection-listing",
                        {
                            position: "absolute",
                            right: 20
                        }
                    )}
                </div>
            </Fragment>
        )
    }
}

export default withRouter(UECPublicHeader)
