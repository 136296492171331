import React, { Component } from "react"
import { generateObfuscatedLink } from "../helpers/SEO"
import { CDN_DOMAIN } from "../config/cdn"

class UECPublicFooter extends Component {
    render() {
        const showContact =
            !this.props.router ||
            (
                this.props.router &&
                this.props.router.pathname &&
                this.props.router.pathname !== '/agence-immobiliere-vitrine'
            )

        return (
            <footer
                style={{
                    background: "#0db3ac",
                    paddingTop: 40,
                    paddingBottom: 60,
                    ...(this.props.style || {})
                }}
            >
                <div className="mr-md-custom2 row footer-logos">
                    <div
                        className="col-md-5"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <div>
                            <img
                                src={`${CDN_DOMAIN}/static/svg/logo-unemplacement-blanc.svg`}
                                alt="Unemplacement.com"
                                title="Unemplacement.com"
                                style={{
                                    marginTop: 20,
                                    width: 200,
                                    margin: "auto"
                                }}
                            />
                        </div>
                        <div
                            className="subtitle-logo"
                            data-nosnippet
                        />
                    </div>
                    <div className="col-md-7">
                        <div
                            className="row"
                            style={{
                                fontFamily:
                                    "Lato, Roboto, Helvetica, Arial, sans-serif",
                                fontSize: 15,
                                color: "white"
                            }}
                        >
                            <div className={`col-md-${showContact ? 4 : 6} footer-menu`}>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        marginBottom: 25
                                    }}
                                >
                                    A propos
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {generateObfuscatedLink("/qui-sommes-nous", "Qui sommes-nous ?")}
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    <a
                                        href="https://blog.unemplacement.com/"
                                        className="white-link"
                                    >
                                        Actualités
                                    </a>
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    <a
                                        href="/guide"
                                        className="white-link"
                                    >
                                        Guide
                                    </a>
                                </div>
                            </div>
                            <div className={`col-md-${showContact ? 4 : 6} footer-menu`}>
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        marginBottom: 25
                                    }}
                                >
                                    Conditions
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {generateObfuscatedLink("/mentions-legales", "Mentions légales")}
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {generateObfuscatedLink("/protection-des-donnees", "Protection des données")}
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                    {generateObfuscatedLink("/cgu", "CGU")}
                                </div>
                            </div>
                            {showContact &&
                                <div className="col-md-4 footer-menu">
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            marginBottom: 25
                                        }}
                                    >
                                        Contactez-nous
                                    </div>
                                    <div style={{ marginBottom: 15 }}>
                                        Téléphone :{" "}
                                        <a
                                            href="tel:+33622497152"
                                            className="white-link"
                                        >
                                            06 22 49 71 52
                                        </a>
                                    </div>
                                    <div style={{ marginBottom: 15 }}>
                                        <a
                                            href="mailto:contact@unemplacement.com?subject=UnEmplacement.com demande d'information"
                                            className="white-link"
                                        >
                                            Envoyer un e-mail
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default UECPublicFooter