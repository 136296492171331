import React, { Component } from "react"
import { CDN_DOMAIN } from "../config/cdn"

class StyleBase extends Component {
    render() {
        return <link rel="stylesheet" href={`${CDN_DOMAIN}/static/base.css`} />
    }
}

export default StyleBase
